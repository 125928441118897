/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-favorite {
	--ion-color-base: var(--ion-color-favorite);
	--ion-color-base-rgb: var(--ion-color-favorite-rgb);
	--ion-color-contrast: var(--ion-color-favorite-contrast);
	--ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
	--ion-color-shade: var(--ion-color-favorite-shade);
	--ion-color-tint: var(--ion-color-favorite-tint);
}
 


.black {
	padding: 1px;
	width: 300px;
	border: 2px solid rgb(50, 96, 2);
}


html,
body {
	width: 100%;
	height: 100%;
	margin: 1px;
}
/*
.colorRadioButton {
	color: #03DAC5;
}
*/
:root {
	--ion-color-rojo: #ff0000;
	--ion-color-rojo-rgb: 255, 0, 0;
	--ion-color-rojo-contrast: #ffffff;
	--ion-color-rojo-contrast-rgb: 255, 255, 255;
	--ion-color-rojo-shade: #e60000;
	--ion-color-rojo-tint: #ff3333;
  }
  