  // Ionic Variables and Theming. For more info, please see:
  // http://ionicframework.com/docs/theming/

  /** Ionic CSS Variables **/
  :root {
    /** favorite **/
    //--ion-color-favorite: #006600;
    --ion-color-favorite: #2E7D32;
    --ion-color-favorite-rgb: 46, 125, 50;
    --ion-color-favorite-contrast: #ffffff;
    --ion-color-favorite-contrast-rgb: 255, 255, 255;
    --ion-color-favorite-shade: #286e2c;
    --ion-color-favorite-tint: #438a47;


    --ion-color-rojo: #ff0000;
    --ion-color-rojo-rgb: 255, 0, 0;
    --ion-color-rojo-contrast: #ffffff;
    --ion-color-rojo-contrast-rgb: 255, 255, 255;
    --ion-color-rojo-shade: #e60000;
    --ion-color-rojo-tint: #ff3333;
   


    /** primary **/
    --ion-color-primary: #388E3C;
    --ion-color-primary-rgb: 56, 142, 60;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #317d35;
    --ion-color-primary-tint: #4c9950;

    /** secondary **/
    --ion-color-secondary: #4CAF50;
    --ion-color-secondary-rgb: 76, 175, 80;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #439a46;
    --ion-color-secondary-tint: #5eb762;
    /** tertiary **/
    --ion-color-tertiary: #64FFDA;
    --ion-color-tertiary-rgb: 100, 255, 218;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #58e0c0;
    --ion-color-tertiary-tint: #74ffde;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  @media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
      /** secondary **/
      --ion-color-secondary: #4CAF50;
      --ion-color-secondary-rgb: 76, 175, 80;
      --ion-color-secondary-contrast: #000000;
      --ion-color-secondary-contrast-rgb: 0, 0, 0;
      --ion-color-secondary-shade: #439a46;
      --ion-color-secondary-tint: #5eb762;
      /** tertiary **/
      --ion-color-tertiary: #64FFDA;
      --ion-color-tertiary-rgb: 100, 255, 218;
      --ion-color-tertiary-contrast: #000000;
      --ion-color-tertiary-contrast-rgb: 0, 0, 0;
      --ion-color-tertiary-shade: #58e0c0;
      --ion-color-tertiary-tint: #74ffde;

      /** success **/
      --ion-color-success: #2dd36f;
      --ion-color-success-rgb: 45, 211, 111;
      --ion-color-success-contrast: #ffffff;
      --ion-color-success-contrast-rgb: 255, 255, 255;
      --ion-color-success-shade: #28ba62;
      --ion-color-success-tint: #42d77d;

      /** warning **/
      --ion-color-warning: #ffc409;
      --ion-color-warning-rgb: 255, 196, 9;
      --ion-color-warning-contrast: #000000;
      --ion-color-warning-contrast-rgb: 0, 0, 0;
      --ion-color-warning-shade: #e0ac08;
      --ion-color-warning-tint: #ffca22;

      /** danger **/
      --ion-color-danger: #eb445a;
      --ion-color-danger-rgb: 235, 68, 90;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
      --ion-color-danger-shade: #cf3c4f;
      --ion-color-danger-tint: #ed576b;

      /** dark **/
      --ion-color-dark: #222428;
      --ion-color-dark-rgb: 34, 36, 40;
      --ion-color-dark-contrast: #ffffff;
      --ion-color-dark-contrast-rgb: 255, 255, 255;
      --ion-color-dark-shade: #1e2023;
      --ion-color-dark-tint: #383a3e;

      /** medium **/
      --ion-color-medium: #92949c;
      --ion-color-medium-rgb: 146, 148, 156;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 255, 255, 255;
      --ion-color-medium-shade: #808289;
      --ion-color-medium-tint: #9d9fa6;

      /** light **/
      --ion-color-light: #f4f5f8;
      --ion-color-light-rgb: 244, 245, 248;
      --ion-color-light-contrast: #000000;
      --ion-color-light-contrast-rgb: 0, 0, 0;
      --ion-color-light-shade: #d7d8da;
      --ion-color-light-tint: #f5f6f9;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
      --ion-background-color: #000000;
      --ion-background-color-rgb: 0, 0, 0;

      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;

      --ion-color-step-50: #0d0d0d;
      --ion-color-step-100: #1a1a1a;
      --ion-color-step-150: #262626;
      --ion-color-step-200: #333333;
      --ion-color-step-250: #404040;
      --ion-color-step-300: #4d4d4d;
      --ion-color-step-350: #595959;
      --ion-color-step-400: #666666;
      --ion-color-step-450: #737373;
      --ion-color-step-500: #808080;
      --ion-color-step-550: #8c8c8c;
      --ion-color-step-600: #999999;
      --ion-color-step-650: #a6a6a6;
      --ion-color-step-700: #b3b3b3;
      --ion-color-step-750: #bfbfbf;
      --ion-color-step-800: #cccccc;
      --ion-color-step-850: #d9d9d9;
      --ion-color-step-900: #e6e6e6;
      --ion-color-step-950: #f2f2f2;

      --ion-item-background: #000000;

      --ion-card-background: #1c1c1d;
    }

    .ios ion-modal {
      --ion-background-color: var(--ion-color-step-100);
      --ion-toolbar-background: var(--ion-color-step-150);
      --ion-toolbar-border-color: var(--ion-color-step-250);
    }


    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
      --ion-background-color: #121212;
      --ion-background-color-rgb: 18, 18, 18;

      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;

      --ion-border-color: #222222;

      --ion-color-step-50: #1e1e1e;
      --ion-color-step-100: #2a2a2a;
      --ion-color-step-150: #363636;
      --ion-color-step-200: #414141;
      --ion-color-step-250: #4d4d4d;
      --ion-color-step-300: #595959;
      --ion-color-step-350: #656565;
      --ion-color-step-400: #717171;
      --ion-color-step-450: #7d7d7d;
      --ion-color-step-500: #898989;
      --ion-color-step-550: #949494;
      --ion-color-step-600: #a0a0a0;
      --ion-color-step-650: #acacac;
      --ion-color-step-700: #b8b8b8;
      --ion-color-step-750: #c4c4c4;
      --ion-color-step-800: #d0d0d0;
      --ion-color-step-850: #dbdbdb;
      --ion-color-step-900: #e7e7e7;
      --ion-color-step-950: #f3f3f3;

      --ion-item-background: #1e1e1e;

      --ion-toolbar-background: #1f1f1f;

      --ion-tab-bar-background: #1f1f1f;

      --ion-card-background: #1e1e1e;
    }

    /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

    /* Core CSS required for Ionic components to work properly */
    @import "~@ionic/angular/css/core.css";

    /* Basic CSS for apps built with Ionic */
    @import "~@ionic/angular/css/normalize.css";
    @import "~@ionic/angular/css/structure.css";
    @import "~@ionic/angular/css/typography.css";
    @import '~@ionic/angular/css/display.css';

    /* Optional CSS utils that can be commented out */
    @import "~@ionic/angular/css/padding.css";
    @import "~@ionic/angular/css/float-elements.css";
    @import "~@ionic/angular/css/text-alignment.css";
    @import "~@ionic/angular/css/text-transformation.css";
    @import "~@ionic/angular/css/flex-utils.css";

    .ion-color-favorite {
      --ion-color-base: var(--ion-color-favorite);
      --ion-color-base-rgb: var(--ion-color-favorite-rgb);
      --ion-color-contrast: var(--ion-color-favorite-contrast);
      --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
      --ion-color-shade: var(--ion-color-favorite-shade);
      --ion-color-tint: var(--ion-color-favorite-tint);
    }



    .black {
      padding: 1px;
      width: 300px;
      border: 2px solid rgb(50, 96, 2);
    }


    html,
    body {
      width: 100%;
      height: 100%;
      margin: 1px;
    }

    /*
.colorRadioButton {
	color: #03DAC5;
}
*/
 




  }